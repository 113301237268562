<template>
  <div>
    <custom-table
      ref="customTable"
      :columns="columns"
      :custom-view="customView"
      :footer-actions="footerActions"
      :sums="sums"
      table-height="calc(100vh - 145px)"
      api-url="/present-list"
      @showDialogCreate="showDialogCreate"
      @showEditProgram="showEditProgram"
      @showDetail="showDetail"
      @cancelProgram="cancelProgram"
      @confirmProgram="confirmProgram"
    ></custom-table>

    <v-dialog v-model="dialogCreateUpdate" persistent max-width="1200px">
      <PresentCreate
        v-if="dialogCreateUpdate"
        :update-item="updateItem"
        :is-detail-show="isDetailShow"
        @close="hideDialogCreate"
        @refreshData="refreshCustomTable"
      />
    </v-dialog>
  </div>
</template>

<script>
import {
  CUSTOM_VIEW_PRESENT_LIST,
  ORDER_SOURCES,
  PRESENT_STATUS,
  PRESENT_TYPES,
} from "@/libs/const";
import { httpClient } from "@/libs/http";
export default {
  name: "Present",
  components: {
    PresentCreate: () => import("@/components/goods/PresentCreate"),
  },
  data: () => ({
    customView: CUSTOM_VIEW_PRESENT_LIST,
    columns: [],
    footerActions: [],
    sums: [],
    isLoading: false,
    sourceOptions: [...ORDER_SOURCES],
    statusOptions: [...PRESENT_STATUS],
    typeOptions: [...PRESENT_TYPES],
    dialogCreateUpdate: false,
    isDetailShow: false,
    updateItem: {},
  }),
  computed: {},
  created() {
    const columns = [
      {
        type: "date-range-filter",
        label: this.$t("labels.create_time"),
        placeholder: this.$t("labels.create_time"),
        name: "created_at",
        hasSort: false,
        sortName: "created_at",
        key: "created_at",
        required: true,
      },
      {
        type: "select-employee",
        label: this.$t("labels.creator_name"),
        placeholder: this.$t("labels.creator_name"),
        name: "id_identity",
        hasSort: false,
        sortName: "id_identity",
        key: "identity_name",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.tracking"),
        placeholder: this.$t("labels.tracking"),
        name: "tracking_id",
        hasSort: false,
        sortName: "tracking_id",
        key: "tracking_id",
        required: true,
        action: "showDetail",
      },
      {
        type: "input-filter",
        label: this.$t("labels.program_name"),
        placeholder: this.$t("labels.program_name"),
        name: "name",
        hasSort: false,
        sortName: "name",
        key: "name",
        required: true,
      },
      {
        type: "select-filter",
        label: this.$t("labels.promotion_type"),
        placeholder: this.$t("labels.promotion_type"),
        name: "type",
        hasSort: false,
        sortName: "type",
        key: "type",
        options: this.typeOptions,
        required: true,
      },
      {
        type: "select-warehouse",
        label: this.$t("labels.warehouse_1"),
        placeholder: this.$t("labels.warehouse_1"),
        name: "id_warehouse",
        hasSort: false,
        sortName: "warehouse_codes",
        key: "warehouse_codes",
        required: true,
        params: {
          "auto-select": false,
        },
      },
      {
        type: "select-filter",
        label: this.$t("labels.order_source"),
        placeholder: this.$t("labels.order_source"),
        name: "sources",
        hasSort: false,
        sortName: "sources",
        key: "source_names",
        options: this.sourceOptions,
        required: true,
      },
      {
        type: "select-e-market-shop",
        label: this.$t("labels.shop"),
        placeholder: this.$t("labels.shop"),
        class: "c-input-xs input-filter",
        name: "id_customer_e_marketplace_shop",
        hasSort: false,
        sortName: "shop_names",
        key: "shop_names",
        required: true,
        params: {
          "is-check-e-market": false,
        },
      },
      {
        type: "date-range-filter",
        label: this.$t("labels.from"),
        placeholder: this.$t("labels.from"),
        name: "start_time",
        hasSort: false,
        sortName: "start_time",
        key: "start_time",
        required: true,
      },
      {
        type: "date-range-filter",
        label: this.$t("labels.to"),
        placeholder: this.$t("labels.to"),
        name: "end_time",
        hasSort: false,
        sortName: "end_time",
        key: "end_time",
        required: true,
      },
      {
        type: "select-filter",
        label: this.$t("labels.status_1"),
        placeholder: this.$t("labels.status_1"),
        name: "status",
        hasSort: false,
        sortName: "status",
        key: "status",
        options: this.statusOptions,
        required: true,
      },
      {
        type: "button",
        label: "",
        headerAction: "showDialogCreate",
        headerActionColor: "primary",
        headerActionLabel: this.$t("labels.add"),
        action: false,
        required: true,
        buttonActions: [
          {
            action: "cancelProgram",
            text: this.$t("labels.cancel"),
            color: "error",
            class: "",
            permissions: [],
            allowKey: "can_cancel",
          },
          {
            action: "cancelProgram",
            text: this.$t("labels.stop"),
            color: "error",
            class: "",
            permissions: [],
            allowKey: "can_stop",
          },
          {
            action: "confirmProgram",
            text: this.$t("labels.confirm"),
            color: "success",
            class: "mt-1",
            permissions: [],
            allowKey: "can_confirm",
          },
          {
            action: "showEditProgram",
            text: this.$t("labels.edit"),
            color: "warning",
            class: "mt-1",
            permissions: [],
            allowKey: "can_update",
          },
        ],
      },
    ];
    this.columns = [...columns];

    const footerActions = [];
    this.footerActions = [...footerActions];

    const sums = [];
    this.sums = [...sums];
  },
  mounted() {},
  methods: {
    refreshCustomTable() {
      this.$refs.customTable.getList();
    },
    showDialogCreate() {
      this.updateItem = {};
      this.dialogCreateUpdate = true;
    },
    hideDialogCreate() {
      this.updateItem = {};
      this.dialogCreateUpdate = false;
      this.isDetailShow = false;
    },
    showEditProgram(item) {
      this.updateItem = { ...item };
      this.dialogCreateUpdate = true;
    },
    showDetail(item) {
      this.isDetailShow = true;
      this.showEditProgram(item);
    },
    async cancelProgram(item) {
      if (!confirm(this.$t("messages.are_you_sure_confirm"))) {
        return false;
      }
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/present-cancel", item);
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.update_success"));
        this.refreshCustomTable();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
    async confirmProgram(item) {
      if (!confirm(this.$t("messages.are_you_sure_confirm"))) {
        return false;
      }
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/present-confirm", item);
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.update_success"));
        this.refreshCustomTable();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
